
import { Prop, Vue, Component, Model, Watch } from 'vue-property-decorator'
import AppInstanceNginxConfigApi from '@/api/maintain/app-instance-nginx-config'

// 实例版本选择组件
@Component({ name: 'DomainSelector', components: {} })
export default class DomainSelector extends Vue {
  @Model('input')
  readonly value?: number | string

  curValue = 0

  @Prop({
    type: String,
    default: '选择主机域名'
  })
  placeholder!: string

  @Prop({
    type: Number,
    required: true
  })
  nginxInstanceId!: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  @Prop({
    type: String,
    default: 'default'
  })
  size!: 'small' | 'default' | 'large'

  loading = false

  datas: Array<any> = []

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('on-select-item', newValue)
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
    for (let i = 0; i < this.datas.length; i++) {
      if (this.datas[i].id === newValue.value) {
        return
      }
    }
  }

  initData () {
    this.$emit('input', undefined)
    this.loadDataFromServer()
  }

  mounted () {
    this.loadDataFromServer()
  }

  @Watch('nginxInstanceId')
  changeNginxInstanceId () {
    this.initData()
  }

  // 加载下拉框选项
  loadDataFromServer () {
    if (!this.nginxInstanceId) {
      this.datas = []
      return
    }
    this.loading = true
    AppInstanceNginxConfigApi.queryServerDomain(this.nginxInstanceId)
      .then((response) => {
        this.datas = response.data || []
        if (this.datas) {
          this.curValue = this.datas[0].serverDomain
        }
      })
      .catch((err) => {
        this.$Notice.error({
          title: '错误',
          desc: '加载版本下拉框失败:' + err.message
        })
      })
    this.loading = false
  }
}
