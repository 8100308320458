import DefaultApiService, { BaseEntity } from '@/libs/api-request'

export interface AppInstanceNginxConfig extends BaseEntity {
  /// 应用实例id
  appInstanceId?: number;

  /// 配置项对应的nginxServer
  nginxServerId?: number;

  /// 配置项类型
  type?: number;

  /// location的访问uri
  locationUri?: string;

  /// location的全路径
  path?: string;

  /// location的访问入口文件名称，如：index.html
  indexFileName?: string;

  /// 说明
  memo?: string;

  /// 挂载的nginx实例Id
  nginxInstanceId?: number;

  /// nginx实例对应的团队id
  teamId?: number;

  // 应用id
  applicationId?: number;
  // 应用id
  nginxPcServerId?: number;
  // 分支
  curGitBrachName?: string;
  // 前端实例名称
  name?: string;
}

class AppInstanceNginxConfigApi extends DefaultApiService<AppInstanceNginxConfig> {
  /**
   * 获取某个nginx实例的server域名集合
   * @param nginxInstanceId
   * @returns
   */
  queryServerDomain (nginxInstanceId: number) {
    return this.requestList(`queryServerDomain/${nginxInstanceId}`, null, 'GET')
  }

  /**
   * 获取某个nginx实例的配置列表
   * @param nginxInstanceId
   * @returns
   */
  getItemsByInstanceId (nginxInstanceId: number) {
    return this.requestList(`getItemsByInstanceId/${nginxInstanceId}`, null, 'GET')
  }

  /**
   * 根据serverId获取配置
   * @param nginxServerId
   * @returns
   */
  getItemsByNginxServerId (nginxServerId: number) {
    return this.requestList(`getItemsByNginxServerId/${nginxServerId}`, null, 'GET')
  }

  constructor () {
    super('/api/maintain/appinstance-nginx-configs')
  }
}

export default new AppInstanceNginxConfigApi()
