
import { AppInstanceNginxServer } from '@/api/maintain/app-instance-nginx-server'
import { showModal } from '@/libs/modal-helper'
import modal from './add-nginx-server-modal.vue'
/**
 * 显示修改nginx服务模态框
 * @param appInstanceId
 * @returns
 */
export default function showAddNginxServerModal (appInstanceId: number): Promise<AppInstanceNginxServer> {
  return new Promise<AppInstanceNginxServer>((resolve, reject) => {
    showModal<AppInstanceNginxServer>(modal, {
      props: {
        appInstanceId
      }
    }, true, (data: AppInstanceNginxServer) => {
      resolve(data)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
