
import { Prop, Vue, Component } from 'vue-property-decorator'
import { Form } from 'view-design'
import appInstanceNginxServerApi, { AppInstanceNginxServer } from '@/api/maintain/app-instance-nginx-server'
import { AttachmentFileData } from '@/api/maintain/attachment-filedata'
import UploadButton from '@/components/upload-button.vue'

@Component({
  name: 'addNginxServerModal',
  components: { UploadButton }
})
export default class AddNginxServerModal extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  appInstanceId!: number

  isShow = false

  formRules = {
    name: [
      {
        trigger: 'blur',
        required: true,
        message: '请输入实例名称'
      }
    ]
  }

  loading = false

  /**
   * 当前表单数据
   */
  addNginxServerData: AppInstanceNginxServer = { nginxInstanceId: this.appInstanceId, useSsl: false } as AppInstanceNginxServer

  /** 上传文件成功 */
  uploadCrtOk (val: AttachmentFileData) {
    this.$set(this.addNginxServerData, 'sslCertPath', `attr:${val.id}`)
  }

  /** 上传文件成功 */
  uploadKeyOk (val: AttachmentFileData) {
    this.$set(this.addNginxServerData, 'sslPrivateKeyPath', `attr:${val.id}`)
  }

  /** 上传等待事件 */
  onLoading (val: boolean) {
    this.loading = val
  }

  show () {
    this.isShow = true
  }

  close () {
    this.isShow = false
  }

  doCancel () {
    this.$emit('onCancel')
  }

  doOk () {
    this.$emit('onOk')
  }

  /// 提交
  submit () {
    const checkform = this.$refs.addAppForm as Form
    checkform.validate((valid) => {
      if (valid) {
        this.loading = true
        this.saveAppData()
      }
    })
  }

  /// 执行保存
  saveAppData () {
    if (
      this.addNginxServerData.useSsl &&
      (!this.addNginxServerData.sslCertPath ||
      !this.addNginxServerData.sslPrivateKeyPath)) {
      this.$Message.error('开启ssl后必须上传crt证书和key证书')
    } else {
      appInstanceNginxServerApi.insertItem(this.addNginxServerData).then((res) => {
        this.loading = false
        this.$emit('onOk', res.data)
      }).catch((err) => {
        this.loading = false
        this.$Message.error(err.message)
      })
    }
  }
}
