
import { Component, Vue } from 'vue-property-decorator'
import AppInstanceNginxConfigApi from '@/api/maintain/app-instance-nginx-config'
import AppInstanceApi, { AppInstance, CreateOrModiAppInstanceAtNginx } from '@/api/maintain/app-instance'
import InstanceNginxSelector from './selector/instance-nginx-selector.vue'
import DomainSelector from './selector/domain-selector.vue'
import PcServerSelectorInApp from './selector/pc-server-selector-in-app.vue'
import AppInstanceNginxServerSelector from './selector/app-instance-nginx-server-selector.vue'
import { AppInstanceNginxServer } from '@/api/maintain/app-instance-nginx-server'
import showAddNginxServerModal from './modal/show-add-nginx-server-modal'
import _ from 'lodash'

@Component({
  name: 'WebfrontContainer',
  components: { InstanceNginxSelector, DomainSelector, PcServerSelectorInApp, AppInstanceNginxServerSelector }
})
export default class WebfrontContainer extends Vue {
  // 实例id
  id = 0;
  // 应用id
  applicationId = 0;
  // 是否为新增表单
  isAddForm = true;
  // 1-使用独立虚拟主机(server) 2-加入某个已存在虚拟主机
  mountType = 1;
  /// 页面模式
  pageMode = 'edit';
  /// 实例数据
  instanceData = {} as AppInstance;

  /** 是否为复制 */
  isCopy=false

  // 初始Data
  restData: CreateOrModiAppInstanceAtNginx = {};
  // 表单数据Data
  appData: CreateOrModiAppInstanceAtNginx = {};

  /** 校验规则 */
  rules={
    instanceName: [
      { required: true, message: '实例名称不能为空', trigger: 'blur' }
    ],
    locationUri: [
      { required: true, message: '请填写uri', trigger: 'blur' }, {
        pattern: /^(\/[a-zA-Z][a-zA-Z0-9-_]+)*\/$/,
        trigger: 'blur',
        message: '请输入英文字母，必须以/开头及结尾'
      }
    ],
    locationIndexFileName: [
      { required: true, message: '入口文件不能为空', trigger: 'blur' }
    ],
    nginxServerId: [
      { type: 'number', required: true, message: '挂载server配置不能为空', trigger: 'blur' }
    ],
    containerName: [{ required: true, message: '实例容器名称不能为空', trigger: 'blur' }, {
      pattern: /^[a-z][a-z0-9_-]*$/,
      trigger: 'blur',
      message: '请输入小写英文字母与数字或下划线的组合，必须英文字母开头'
    }]
  }

  /// 初始化新增模式数据
  initForNew () {
    /// 从路由参数里获取实例基础信息
    const query = this.$route.query
    this.instanceData.applicationId = parseInt(query.appId as string)
    this.instanceData.applicationName = query.appName as string
    this.instanceData.applicationType = parseInt(query.appType as string)
    this.instanceData.applicationTypeTitle = query.appTypeName as string
    this.appData.locationIndexFileName = 'index.html'
    this.appData.appId = this.instanceData.applicationId
    this.isAddForm = true
    this.restData = { locationIndexFileName: 'index.html' }
  }

  activated () {
    const copy = this.$route.params.copy
    if (copy === 'true') {
      this.pageMode = 'new'
      this.isAddForm = true
      this.id = parseInt(this.$route.params.id)
      AppInstanceApi.getItemById(this.id).then((response) => {
        this.instanceData = response.data!
        this.instanceData.id = undefined
        AppInstanceNginxConfigApi.getItemsByInstanceId(this.id).then((response) => {
          if (response.data && response.data.length > 0) {
            this.appData = {
              appId: this.instanceData.applicationId,
              instanceName: `${this.instanceData.name}_copy`,
              containerName: `${this.instanceData.containerName}_copy`,
              nginxInstanceId: response.data[0].nginxInstanceId,
              nginxServerId: response.data[0].nginxServerId,
              locationIndexFileName: response.data[0].indexFileName,
              locationUri: response.data[0].locationUri
            }
            this.restData = JSON.parse(JSON.stringify(this.appData))
          }
        })
      })
      return
    }
    if (this.$route.params.id.match(/^\d+$/)) {
      this.pageMode = 'edit'
      this.id = parseInt(this.$route.params.id)
      this.loadDataFromServer()
    } else {
      this.pageMode = 'new'
      this.initForNew()
    }
  }

  /** 设置实例名 */
  setInstanceName (val: string) {
    this.$set(this.appData, 'instanceName', val)
    if (this.pageMode === 'new') { this.$set(this.appData, 'containerName', _.lowerFirst(val).replace(/[A-Z]+/g, (val) => `_${val.toLowerCase()}`)) }
    this.appData.instanceName = val
  }

  /// 从服务器拉取数据
  loadDataFromServer () {
    AppInstanceApi.getItemById(this.id).then((response) => {
      this.instanceData = response.data!
      AppInstanceNginxConfigApi.getItemsByInstanceId(this.id).then((response) => {
        if (response.data && response.data.length > 0) {
          this.isAddForm = false
          this.appData = {
            instanceId: this.instanceData.id,
            appId: this.instanceData.applicationId,
            instanceName: this.instanceData.name,
            containerName: this.instanceData.containerName,
            nginxInstanceId: response.data[0].nginxInstanceId,
            nginxServerId: response.data[0].nginxServerId,
            locationIndexFileName: response.data[0].indexFileName,
            locationUri: response.data[0].locationUri
          }
          this.restData = JSON.parse(JSON.stringify(this.appData))
        } else this.isAddForm = true
      })
    })
  }

  /** 创建新server */
  addNewServer () {
    if (this.appData.nginxInstanceId) {
      showAddNginxServerModal(this.appData.nginxInstanceId).then((res) => {
        this.appData.nginxServerId = res.id
        this.appData.useSsl = res.useSsl
        this.appData.serverDomain = res.serverDomain
        this.appData.serverPort = res.serverPort
        ;(this.$refs.nginxServerSelector as any).loadDataFromServer()
      })
    } else {
      this.$Message.error('请先选择nginx实例')
    }
  }

  /// 选择服务配置事件
  selectNginxServer (item?: AppInstanceNginxServer) {
    this.$set(this.appData, 'useSsl', item?.useSsl)
    this.$set(this.appData, 'serverDomain', item?.serverDomain)
    this.$set(this.appData, 'serverPort', item?.serverPort)
  }

  // 重置表单
  handleRest () {
    if (this.pageMode === 'new') {
      this.instanceData.pcServerId = undefined
      this.instanceData.name = ''
    }
    this.appData = JSON.parse(JSON.stringify(this.restData))
  }

  // 保存表单
  handleSubmit () {
    (this.$refs.appForm as any).validate((valid: any) => {
      if (valid) {
        this.saveItem()
      }
    })
  }

  /// 保存数据
  saveItem () {
    this.handleSave().then((res) => {
      this.$Notice.success({
        title: '成功',
        desc: '操作成功'
      })
    }).catch((err) => {
      this.$Notice.error({
        title: '失败',
        desc: err.message
      })
    })
  }

  /**
   * 执行保存
   */
  handleSave () {
    if (this.isAddForm) {
      return AppInstanceApi.createNewAppInstanceAtNginx(this.appData)
    }
    return AppInstanceApi.updateAppInstanceAtNginx(this.appData)
  }

  selectServerDomain (data: any) {
    this.appData.serverDomain = data.label
  }
}
